/* eslint-disable import/prefer-default-export */
import { APIS, token } from './constant';

export const sendEmail = async (payload: any): Promise<any> => {
  try {
    const response = await fetch(APIS.SEND_MAIL, {
      method: 'POST',
      headers: {
        accept: '*/*',
        apiKey: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 401) {
      sessionStorage.removeItem('token');
      throw new Error('Authentication Failed');
    }

    if (response.status !== 200) {
      throw new Error('Failed to send email');
    }

    return response;
  } catch (error: any) {
    throw new Error(`Failed to send email: ${error.message}`);
  }
};
