import { combineReducers } from 'redux';
import { sendMailReducer } from '../modules/modals/contactFormModal/sendMailRedux/sendMail.reducer';
import { paymentIntentListReducer } from '../modules/paymentModule/components/paymentInformation/paymentIntentRedux/paymentIntent.reducer';
import { productListReducer } from '../modules/paymentModule/components/planInformation/productRedux/Product.reducer';
import { priceListReducer } from '../modules/paymentModule/components/paymentInformation/paymentRedux/Payment.reducer';
import { CustomerListReducer } from '../modules/paymentModule/components/userInformation/customerRedux/Customer.reducer';

export default combineReducers({
  CustomerListReducer,
  priceListReducer,
  productListReducer,
  paymentIntentListReducer,
  sendMailReducer,
});
