export const baseURL = import.meta.env.VITE_API_URL;

export const sendMailURL = import.meta.env.VITE_SEND_MAIL_URL;

export const token = import.meta.env.VITE_API_KEY;

export const APIS = {
  GET_ALL_SUBSCRIPTION_LIST: `${baseURL}/subscription/getAllSubscriptionList`,
  GET_ALL_CUSTOMERS: `${baseURL}/customer/getAllStripeCustomers`,
  CREATE_CUSTOMER: `${baseURL}/customer/createCustomers`,
  TOKEN_VALIDATOR: `${baseURL}/getUserDetails`,
  GET_ALL_PRICES: `${baseURL}/price/getAllPrices`,
  GET_ALL_PRODUCTS: `${baseURL}/products/getAllProducts`,
  CREATE_PAYMENT_INTENT: `${baseURL}/payment-intent/createNewPaymentIntent`,
  CREATE_SUBSCRIPTION: `${baseURL}/subscription/createSubscription`,
  UPDATE_SUBSCRIPTION: `${baseURL}/subscription/updateSubscription`,
  GET_ALL_PAYMENT_INTENT: `${baseURL}/payment-intent/getAllPaymentIntent`,
  GET_SELECTED_CUSTOMER_DATA: `${baseURL}/customer/getSelctedCustomer`,
  SEND_MAIL: `${sendMailURL}/api/send-grid/sendMail`,
  CANCEL_SUBSCRIPTION: `${baseURL}/subscription/cancelSubscription`,
};
