import {
  PaymentElement,
  useElements,
  useStripe,
  AddressElement,
} from '@stripe/react-stripe-js';
import { FormEvent, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
// import { toast } from 'react-toastify';

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | any>('');
  const [isPaymentElementComplete, setPaymentElementComplete] = useState(false);
  const [isAddressElementComplete, setAddressElementComplete] = useState(false);

  const handlePaymentElementChange = (event: any) => {
    setPaymentElementComplete(event.complete);
  };

  const handleAddressElementChange = (event: any) => {
    setAddressElementComplete(event.complete);
  };

  const isFormComplete = isPaymentElementComplete && isAddressElementComplete;

  const handlePaymentSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const redirectUrl = sessionStorage.getItem('redirect_url');
    if (!redirectUrl) throw new Error("redirect_url can't be empty");
    setPaymentLoading(true);
    stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    }).then(() => {
      // toast.success('Payment successful!');
      sessionStorage.clear();
    }).catch((error) => setErrorMessage(error.message)).finally(() => setPaymentLoading(false));
  };

  return (
    <div>
      <form
        style={{ display: 'block', width: '100%' }}
        onSubmit={handlePaymentSubmit}
        id="payment-form"
      >
        <div>
          <PaymentElement id="payment-element" onChange={handlePaymentElementChange} />
          <div className="mt-2">
            <AddressElement id="payment-address" options={{ mode: 'billing' }} onChange={handleAddressElementChange} />
          </div>
          <div className="w-full my-4 mt-6 flex items-center justify-center">
            <button
              type="submit"
              id="submit"
              className={`py-3 px-28 rounded-md w-full shadow-md capitalize overflow-hidden relative bg-primary text-white text-lg leading-6 ${
                isPaymentLoading || !isFormComplete ? 'opacity-50 cursor-not-allowed' : ''
              } flex items-center gap-2 justify-center`}
              disabled={isPaymentLoading || !isFormComplete}
            >
              {isPaymentLoading ? (
                <>
                  <RotatingLines
                    strokeColor="white"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible
                  />
                  Processing...
                </>
              ) : 'Pay Now'}
            </button>
          </div>
          <div id="error-message">
            <p className="text-sm font-medium text-red-500">{errorMessage}</p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CheckoutForm;
