/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import MonthlyBillingPlans from './MonthlyBillingPlans';
// import YearlyBillingPlans from './YearlyBillingPlans';
import { getProductList } from './productRedux/Product.thunks';
import { Product } from '../../../../@types/product.api';
import { getPriceList } from '../paymentInformation/paymentRedux/Payment.thunks';
import Footer from '../../../../components/Footer';

interface Props {
  activeComponentIndex: number;
  setActiveComponentIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectActiveComponent: React.Dispatch<React.SetStateAction<string>>;
  setSelectedPriceId: React.Dispatch<React.SetStateAction<string>>;
  handlePrevious: () => void;
}

const mapStateToProps = (state: AppState) => ({
  pricingList: state.priceListReducer.priceList,
  productList: state.productListReducer.productList,
  isLoading: state.priceListReducer.loading,
});

const mapDispatchToProps = {
  getAllPriceList: getPriceList,
  getAllProductList: getProductList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface Props extends ConnectedProps<typeof connector> {}

function PlanInformation(props: Props) {
  const {
    activeComponentIndex,
    setActiveComponentIndex,
    setSelectActiveComponent,
    getAllProductList,
    productList,
    getAllPriceList,
    pricingList,
    setSelectedPriceId,
    handlePrevious,
  } = props;

  const [selectedData, setSelectedData] = useState<Product[]>([]);

  useEffect(() => {
    getAllProductList();

    getAllPriceList();
  }, [getAllProductList, getAllPriceList]);

  const subscribeList = JSON.parse(import.meta.env.VITE_SUBSCRIBE_LIST);

  useEffect(() => {
    const subscribeArray = productList.prices?.filter((product: Product) => subscribeList.includes(product.name));

    const orderedSubscribeArray = subscribeArray?.sort(
      (a: Product, b: Product) => subscribeList.indexOf(a.name) - subscribeList.indexOf(b.name),
    );

    setSelectedData(orderedSubscribeArray);
  }, [productList.prices]);

  return (
    <div>
      <div className="py-4">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-[#111827] text-5xl font-extrabold leading-[48px]">
            Pricing Plans
          </h1>
        </div>
        <div className="w-full py-8">
          <MonthlyBillingPlans
            pricingList={pricingList}
            productList={selectedData}
            activeComponentIndex={activeComponentIndex}
            setActiveComponentIndex={setActiveComponentIndex}
            setSelectActiveComponent={setSelectActiveComponent}
            setSelectedPriceId={setSelectedPriceId}
          />
        </div>
      </div>
      <div
        className={`w-full ${activeComponentIndex === 3 ? 'hidden' : 'flex'}`}
      >
        <div className="border-t-[1px] border-[#E5E7EB] pt-6 w-full flex items-center justify-center">
          <Footer
            handlePrevious={handlePrevious}
            handleNext={() => {}}
            activeComponentIndex={activeComponentIndex}
            loading={false}
            showPreviousButton
            showNextButton={false}
          />
        </div>
      </div>
    </div>
  );
}

export default connector(PlanInformation);
