import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PaymentScreen from './pages/Payment';
import NotFoundScreen from './pages/NotFound';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<PaymentScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
