/* eslint-disable import/prefer-default-export */
import { getAllProducts } from '../../../../../api/product.api';
import * as actions from './Product.actions';

export const getProductList = () => (dispatch: any) => {
  dispatch(actions.getProductListRequested());
  return getAllProducts()
    .then((res) => dispatch(actions.getProductListSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.getProductListFailed(err))));
};
