import {
  ResGetClientSecret,
  ResGetSubscription,
} from '../@types/subscription.api';
import { ResCancelSubscription } from '../@types/CancelSubscription.type';
import { token, APIS } from './constant';

export const getAllSubscriptionList = (): Promise<ResGetSubscription> => new Promise((resolve, reject) => {
  fetch(APIS.GET_ALL_SUBSCRIPTION_LIST, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(async (res) => {
      if (res.status === 401) {
        sessionStorage.removeItem('token');
        reject(new Error('Authentication Failed'));
      }
      resolve({
        data: {
          subscription: await res.json().then((value) => value.data),
          message: 'All subscription list Retrieved',
        },
        message: 'All subscription list Retrieved',
      });
    })
    .catch((error) => {
      reject(new Error(error));
    });
});

export const createClientSubscriptionSecret = async (
  customerId: string,
  priceId: string,
): Promise<ResGetClientSecret> => {
  const payload = {
    items: [
      {
        price: priceId,
      },
    ],
    customer: customerId,
    payment_behavior: 'default_incomplete',
    payment_settings: {
      save_default_payment_method: 'on_subscription',
    },
    expand: ['latest_invoice.payment_intent'],
  };

  try {
    const response = await fetch(APIS.CREATE_SUBSCRIPTION, {
      method: 'POST',
      headers: {
        accept: '*/*',
        apiKey: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 401) {
      throw new Error('Authentication Failed');
    }

    const responseData = await response.json();

    return {
      data: responseData,
      message: 'Created Subscription successfully',
    };
  } catch (error: any) {
    throw new Error(`Failed to create subscription: ${error.message}`);
  }
};

export const updateClientSubscriptionSecret = async (
  subscriptionId: string,
  itemId: string,
  priceId: string,
): Promise<ResGetClientSecret> => {
  const payload = {
    payment_behavior: 'pending_if_incomplete',
    proration_behavior: 'always_invoice',
    items: [
      {
        id: itemId,
        price: priceId,
      },
    ],
    expand: ['latest_invoice.payment_intent'],
  };

  try {
    const response = await fetch(
      `${APIS.UPDATE_SUBSCRIPTION}?id=${subscriptionId}`,
      {
        method: 'POST',
        headers: {
          accept: '*/*',
          apiKey: `${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    );

    if (response.status === 401) {
      throw new Error('Authentication Failed');
    }

    const responseData = await response.json();

    return {
      data: responseData,
      message: 'Updated Subscription successfully',
    };
  } catch (error: any) {
    throw new Error(`Failed to update subscription: ${error.message}`);
  }
};

export const cancelClientSubscriptionSecret = async (
  subscriptionId: string,
): Promise<ResCancelSubscription> => {
  try {
    const response = await fetch(
      `${APIS.CANCEL_SUBSCRIPTION}?id=${subscriptionId}`,
      {
        method: 'POST',
        headers: {
          accept: '*/*',
          apiKey: `${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status === 401) {
      throw new Error('Authentication Failed');
    }
    const responseData = await response.json();
    return {
      data: responseData,
      message: 'Canceled Subscription successfully',
    };
  } catch (error: any) {
    throw new Error(`Failed to cancel subscription: ${error.message}`);
  }
};
