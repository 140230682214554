import {
  createCustomer, getAllCustomers, getSelectedCustomers, validateUser,
} from '../../../../../api/customer.api';
import * as actions from './Customer.action';

export const getCustomerList = () => (dispatch: any) => {
  dispatch(actions.getCustomersListRequested());
  return getAllCustomers()
    .then((res) => dispatch(actions.getCustomersListSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.getCustomersListFailed(err))));
};

export const createNewCustomer = (payload: any) => (dispatch: any) => {
  dispatch(actions.createCustomerRequested());
  return createCustomer(payload)
    .then((res) => dispatch(actions.createCustomerSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.createCustomerFailed(err))));
};

export const getSelectedCustomerData = (customerId: string) => (dispatch: any) => {
  dispatch(actions.getCustomerDataRequested());
  return getSelectedCustomers(customerId)
    .then((res) => dispatch(actions.getCustomerDataSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.getCustomerDataFailed(err))));
};

export const validateCustomer = (payload: any) => (dispatch: any) => {
  dispatch(actions.getValidatorCustomerDataRequested());
  return validateUser(payload)
    .then((res) => dispatch(actions.getValidatorCustomerDataSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.getValidatorCustomerDataFailed(err))));
};
