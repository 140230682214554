export const GET_ALL_CUSTOMERS_REQUESTED = 'views/Home/GET_ALL_CUSTOMERS_REQUESTED';
export const GET_ALL_CUSTOMERS_SUCCESS = 'views/Home/GET_ALL_CUSTOMERS_SUCCESS';
export const GET_ALL_CUSTOMERS_FAILED = 'views/Home/GET_ALL_CUSTOMERS_FAILED';

export const CREATE_CUSTOMERS_REQUESTED = 'views/Home/CREATE_CUSTOMERS_REQUESTED';
export const CREATE_CUSTOMERS_SUCCESS = 'views/Home/CREATE_CUSTOMERS_SUCCESS';
export const CREATE_CUSTOMERS_FAILED = 'views/Home/CREATE_CUSTOMERS_FAILED';

export const GET_CUSTOMER_DATA_REQUESTED = 'views/Home/GET_CUSTOMER_DATA_REQUESTED';
export const GET_CUSTOMER_DATA_SUCCESS = 'views/Home/GET_CUSTOMER_DATA_SUCCESS';
export const GET_CUSTOMER_DATA_FAILED = 'views/Home/GET_CUSTOMER_DATA_FAILED';

export const GET_VALIDATOR_CUSTOMER_DATA_REQUESTED = 'views/Home/GET_VALIDATOR_CUSTOMER_DATA_REQUESTED';
export const GET_VALIDATOR_CUSTOMER_DATA_SUCCESS = 'views/Home/GET_VALIDATOR_CUSTOMER_DATA_SUCCESS';
export const GET_VALIDATOR_CUSTOMER_DATA_FAILED = 'views/Home/GET_VALIDATOR_CUSTOMER_DATA_FAILED';
