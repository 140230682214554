import * as types from './sendMail.constants';

export const sendMailRequested = () => ({
  type: types.SEND_MAIL_REQUESTED,
});

export const sendMailSuccess = (payload: any) => ({
  type: types.SEND_MAIL_SUCCESS,
  payload,
});

export const sendMailFailed = (payload: any) => ({
  type: types.SEND_MAIL_FAILED,
  payload,
});
