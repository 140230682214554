/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable import/prefer-default-export */
import * as types from './Customer.constants';

const initialState = {
  loading: false,
  createUserLoading: false,
  customerList: [] as any[],
  customerData: [] as any[],
  validateUser: {},
};

export const CustomerListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ALL_CUSTOMERS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerList: action.payload.data,
      };

    case types.GET_ALL_CUSTOMERS_FAILED:
      return {
        ...state,
        loading: false,
        customerList: [],
      };

    case types.GET_CUSTOMER_DATA_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.GET_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        customerData: action.payload.data,
      };

    case types.GET_CUSTOMER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        customerData: [],
      };

    case types.CREATE_CUSTOMERS_REQUESTED:
      return {
        ...state,
        createUserLoading: true,
      };

    case types.CREATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
      };

    case types.CREATE_CUSTOMERS_FAILED:
      return {
        ...state,
        createUserLoading: false,
      };

    case types.GET_VALIDATOR_CUSTOMER_DATA_REQUESTED:
      return {
        ...state,
        createUserLoading: true,
        loading: true,
      };

    case types.GET_VALIDATOR_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        validateUser: action.payload.data,
        loading: false,
      };

    case types.GET_VALIDATOR_CUSTOMER_DATA_FAILED:
      return {
        ...state,
        createUserLoading: false,
        loading: false,
      };

    default:
      return state;
  }
};
