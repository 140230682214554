import { useState } from 'react';
import { Price } from '../../../../@types/price.api';
import { Product } from '../../../../@types/product.api';
import TickIcon from '../../../../assets/TickIcon.svg';
import { steps } from '../../../../constants';
import ContactFormModal from '../../../modals/contactFormModal/ContactFormModal';

interface Props {
  pricingList: any;
  productList: Product[];
  activeComponentIndex: number;
  setActiveComponentIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectActiveComponent: React.Dispatch<React.SetStateAction<string>>;
  setSelectedPriceId: React.Dispatch<React.SetStateAction<string>>;
}

function MonthlyBillingPlans(props: Props) {
  const {
    productList,
    activeComponentIndex,
    setActiveComponentIndex,
    setSelectActiveComponent,
    pricingList,
    setSelectedPriceId,
  } = props;

  const [openContactForm, setOpenContactForm] = useState(false);
  const getPriceForProduct = (productId: string) => {
    const priceData = pricingList.prices?.find(
      (price: Price) => price.product === productId,
    );

    if (priceData) {
      const price = (priceData.unit_amount / 100).toFixed(2);
      return {
        price: price.slice(0, -3),
        priceId: priceData.id,
      };
    }
    return null;
  };

  const handleNextStep = (priceId: string) => {
    const newIndex = activeComponentIndex + 1;
    setSelectActiveComponent(steps[newIndex].stepName);
    setActiveComponentIndex(newIndex);
    setSelectedPriceId(priceId);
  };

  return (
    <>
      <div className="lg:px-24 px-4">
        <div className="flex lg:flex-row flex-col gap-6 w-fit justify-center">
          {productList?.map((plan: Product) => (
            <div
              key={plan.id}
              className="relative bg-white border-[1px] lg:w-[314px] w-full rounded-lg border-gray-200 hover:shadow-xl flex flex-col justify-between
              transition-all duration-300 ease-in-out"
              style={{ flex: '1' }}
            >
              <div className="p-10">
                <h2 className="text-3xl font-semibold text-[#111827] text-center">
                  {plan.name}
                </h2>
                <h1 className="text-sm text-[#6B7280] font-medium py-6 text-center">
                  {plan.description}
                </h1>
                <div>
                  {plan.name === 'Starter' ? (
                    <h1 className="text-base font-semibold text-[#111827]">
                      Includes:
                      {' '}
                    </h1>
                  ) : (
                    <h1 className="text-base font-semibold text-[#111827]">
                      Top Features:
                      {' '}
                    </h1>
                  )}
                </div>
                <div className="list-disc list-inside pt-3">
                  {plan.features.map((feature) => (
                    <div key={feature.name} className="mb-2 flex gap-3">
                      <div>
                        <img src={TickIcon} alt="tick" />
                      </div>
                      <h1 className="text-[#6B7280] text-base font-medium">
                        {feature.name}
                      </h1>
                    </div>
                  ))}
                </div>
              </div>
              <div className="px-10">
                {plan.name === 'Enterprise' && (
                  <div>
                    <button
                      onClick={() => setOpenContactForm(true)}
                      type="button"
                      className="py-3 mb-10 mt-8 px-6 rounded w-full button-shadow bg-sky-900 text-white uppercase overflow-hidden relative leading-6 focus:ring-0
                      hover:bg-sky-950 transition-all duration-300 ease-in-out hover:scale-105"
                    >
                      Contact Now
                    </button>
                  </div>
                )}
                {plan.name !== 'Enterprise' && (
                  <div>
                    <div className="flex items-center">
                      <p className="text-4xl font-medium">$</p>
                      <p className="text-[#111827] text-6xl leading-[60px]">
                        {getPriceForProduct(plan.id)?.price || ''}
                      </p>
                      <p className="text-2xl text-[#6B7280] font-medium">
                        {' '}
                        /month
                      </p>
                    </div>
                    <button
                      type="button"
                      className="px-6 py-3 mb-10 mt-8 rounded hover:bg-orange-600 w-full button-shadow uppercase overflow-hidden relative leading-6 bg-primary text-white
                       transition-all duration-300 ease-in-out hover:scale-105"
                      onClick={() => handleNextStep(getPriceForProduct(plan.id)?.priceId)}
                    >
                      Buy Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {openContactForm && (
        <ContactFormModal
          openModal={openContactForm}
          setOpenModal={setOpenContactForm}
        />
      )}
    </>
  );
}

export default MonthlyBillingPlans;
