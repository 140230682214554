export const GET_ALL_PRICES_REQUESTED = 'views/Home/GET_ALL_PRICES_REQUESTED';
export const GET_ALL_PRICES_SUCCESS = 'views/Home/GET_ALL_PRICES_SUCCESS';
export const GET_ALL_PRICES_FAILED = 'views/Home/GET_ALL_PRICES_FAILED';

export const CREATE_CLIENT_SECRET_REQUESTED = 'views/payment/CREATE_CLIENT_SECRET_REQUESTED';
export const CREATE_CLIENT_SECRET_SUCCESS = 'views/payment/CREATE_CLIENT_SECRET_SUCCESS';
export const CREATE_CLIENT_SECRET_FAILED = 'views/payment/CREATE_CLIENT_SECRET_FAILED';

export const UPDATE_CLIENT_SECRET_REQUESTED = 'views/payment/UPDATE_CLIENT_SECRET_REQUESTED';
export const UPDATE_CLIENT_SECRET_SUCCESS = 'views/payment/UPDATE_CLIENT_SECRET_SUCCESS';
export const UPDATE_CLIENT_SECRET_FAILED = 'views/payment/UPDATE_CLIENT_SECRET_FAILED';

export const CANCEL_CLIENT_SECRET_REQUESTED = 'views/payment/CANCEL_CLIENT_SECRET_REQUESTED';
export const CANCEL_CLIENT_SECRET_SUCCESS = 'views/payment/CANCEL_CLIENT_SECRET_SUCCESS';
export const CANCEL_CLIENT_SECRET_FAILED = 'views/payment/CANCEL_CLIENT_SECRET_FAILED';
