/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable import/prefer-default-export */
import * as types from './sendMail.constants';

const initialState = {
  loading: false,
};

export const sendMailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SEND_MAIL_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.SEND_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.SEND_MAIL_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
