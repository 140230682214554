import * as types from './Product.constants';

export const getProductListRequested = () => ({
  type: types.GET_ALL_PRODUCTS_REQUESTED,
});

export const getProductListSuccess = (payload: any) => ({
  type: types.GET_ALL_PRODUCTS_SUCCESS,
  payload,
});

export const getProductListFailed = (payload: any) => ({
  type: types.GET_ALL_PRODUCTS_FAILED,
  payload,
});
