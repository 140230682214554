import {
  ResGetAllCustomers,
  ResGetSelectedCustomer,
} from '../@types/customer.api';
import { token, APIS } from './constant';

export const getAllCustomers = (): Promise<ResGetAllCustomers> => new Promise((resolve, reject) => {
  fetch(APIS.GET_ALL_CUSTOMERS, {
    method: 'GET',
    headers: {
      accept: '*/*',
      apiKey: token,
    },
  })
    .then(async (res) => {
      if (res.status === 401) {
        sessionStorage.removeItem('token');
        reject(new Error('Authentication Failed'));
      }
      resolve({
        data: {
          customers: await res.json().then((value) => value.data),
          message: 'All customer list Retrieved',
        },
        message: 'All customer list Retrieved',
      });
    })
    .catch(() => {
      reject(new Error('Failed to retrieve customer list'));
    });
});

export const getSelectedCustomers = (
  customerId: string,
): Promise<ResGetSelectedCustomer> => new Promise((resolve, reject) => {
  fetch(`${APIS.GET_SELECTED_CUSTOMER_DATA}?id=${customerId}`, {
    method: 'GET',
    headers: {
      accept: '*/*',
      apiKey: token,
    },
  })
    .then(async (res) => {
      if (res.status === 401) {
        sessionStorage.removeItem('token');
        reject(new Error('Authentication Failed'));
      }
      resolve({
        data: {
          customer: await res.json().then((value) => value),
          message: 'Customer Retrieved',
        },
        message: 'Customer Retrieved',
      });
    })
    .catch(() => {
      reject(new Error('Failed to retrieve customer'));
    });
});

export const validateUser = async (data: {
  access_token: string;
}): Promise<ResGetAllCustomers> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    access_token,
  } = data;
  try {
    const response = await fetch(`${APIS.TOKEN_VALIDATOR}?access_token=${access_token}`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        apiKey: token,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 401) {
      sessionStorage.removeItem('token');
      throw new Error('Authentication Failed');
    }

    const responseData = await response.json();

    return {
      data: responseData,
      message: 'Customer validated successfully',
    };
  } catch (error: any) {
    throw new Error(`Failed to validate customer: ${error.message}`);
  }
};

export const createCustomer = async (data: {
  description: string;
  email: string;
  phone: string;
  name: string;
  metadata: Record<string, any>;
}): Promise<ResGetAllCustomers> => {
  const {
    description, email, phone, name, metadata,
  } = data;
  const payload = {
    description,
    email,
    phone,
    name,
    metadata,
  };

  try {
    const response = await fetch(APIS.CREATE_CUSTOMER, {
      method: 'POST',
      headers: {
        accept: '*/*',
        apiKey: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 401) {
      sessionStorage.removeItem('token');
      throw new Error('Authentication Failed');
    }

    const responseData = await response.json();

    return {
      data: {
        customers: responseData,
        message: 'Created Customer successfully',
      },
      message: 'Created Customer successfully',
    };
  } catch (error: any) {
    throw new Error(`Failed to create customer: ${error.message}`);
  }
};
