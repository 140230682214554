/* eslint-disable import/order */
/* eslint-disable import/no-named-as-default */
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import Store from './store/store';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      instrumentPageLoad: true,
      instrumentNavigation: true,
      markBackgroundSpan: true,
      enableLongTask: true,
      traceFetch: true,
      traceXHR: true,
      enableHTTPTimings: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: true, // very important in the payment gateway
      sessionSampleRate: 1,
      errorSampleRate: 1.0,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Sentry.setTag('aws_account_id', import.meta.env.VITE_AWS_ACCOUNT_ID);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={Store}>
    <BrowserRouter>
      <PrimeReactProvider
        value={{ unstyled: true, pt: Tailwind, ripple: true }}
      >
        <App />
      </PrimeReactProvider>
    </BrowserRouter>
  </Provider>,
);
