export const steps = [
  { id: 1, stepName: 'Choose your plan' },
  { id: 2, stepName: 'Payment information' },
];

export const monthlyBillingPlansData = [
  {
    plan_name: 'Basic',
    billing_amount: 19.99,
    billing_type: 'Monthly',
    features: ['Basic Features', 'Limited Support'],
    mostPopular: false,
  },
  {
    plan_name: 'Standard',
    billing_amount: 49.99,
    billing_type: 'Monthly',
    features: [
      'Standard Features',
      'Email Support',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      'Praesent eget arcu at nunc dictum tincidunt ac in sem.',
      'Nullam auctor orci sit amet turpis scelerisque, ut vestibulum mauris laoreet.',
      'Sed euismod justo nec mauris tincidunt, eget venenatis augue bibendum.',
    ],
    mostPopular: true,
  },
  {
    plan_name: 'Enterprise',
    billing_amount: 99.99,
    billing_type: 'Monthly',
    features: [
      'Advanced Features',
      'Priority Support',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      'Proin vel eros vel ex euismod vehicula.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ],
    mostPopular: false,
  },
];

export const yearlyBillingPlansData = [
  {
    plan_name: 'Basic',
    billing_amount: 199.99,
    billing_type: 'Yearly',
    features: ['Basic Features', 'Limited Support'],
    mostPopular: false,
  },
  {
    plan_name: 'Standard',
    billing_amount: 499.99,
    billing_type: 'Yearly',
    features: [
      'Standard Features',
      'Email Support',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      'Praesent eget arcu at nunc dictum tincidunt ac in sem.',
      'Nullam auctor orci sit amet turpis scelerisque, ut vestibulum mauris laoreet.',
      'Sed euismod justo nec mauris tincidunt, eget venenatis augue bibendum.',
    ],
    mostPopular: true,
  },
  {
    plan_name: 'Enterprise',
    billing_amount: 999.99,
    billing_type: 'Yearly',
    features: [
      'Advanced Features',
      'Priority Support',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      'Proin vel eros vel ex euismod vehicula.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ],
    mostPopular: false,
  },
];
