import { getAllPrices } from '../../../../../api/price.api';
import { createClientSubscriptionSecret, updateClientSubscriptionSecret, cancelClientSubscriptionSecret } from '../../../../../api/subscription.api';
import * as actions from './Payment.actions';

export const getPriceList = () => (dispatch: any) => {
  dispatch(actions.getPriceListRequested());
  return getAllPrices()
    .then((res) => dispatch(actions.getPriceListSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.getPriceListFailed(err))));
};

export const createClientSecret = (customerId: string, priceId: string) => (dispatch: any) => {
  dispatch(actions.createClientSecretRequested());
  return createClientSubscriptionSecret(customerId, priceId)
    .then((res) => dispatch(actions.createClientSecretSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.createClientSecretFailed(err))));
};

export const updateClientSecret = (
  subscriptionId: string,
  itemId: string,
  priceId: string,
) => (dispatch: any) => {
  dispatch(actions.updateClientSecretRequested());
  return updateClientSubscriptionSecret(subscriptionId, itemId, priceId)
    .then((res) => dispatch(actions.updateClientSecretSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.updateClientSecretFailed(err))));
};

export const cancelClientSecret = (subscriptionId: string) => (dispatch: any) => {
  dispatch(actions.cancelClientSecretRequested());
  return cancelClientSubscriptionSecret(subscriptionId)
    .then((res) => dispatch(actions.cancelClientSecretSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.cancelClientSecretFailed(err))));
};
