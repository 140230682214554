/* eslint-disable import/prefer-default-export */
import { ResGetAllPrices } from '../@types/price.api';
import { APIS, token } from './constant';

export const getAllPrices = (): Promise<ResGetAllPrices> => new Promise((resolve, reject) => {
  fetch(APIS.GET_ALL_PRICES, {
    method: 'GET',
    headers: {
      accept: '*/*',
      apiKey: token,
    },
  })
    .then(async (res) => {
      if (res.status === 401) {
        sessionStorage.removeItem('token');
        reject(new Error('Authentication Failed'));
      }
      resolve({
        data: {
          prices: await res.json().then((value) => value.data),
          message: 'All prices list Retrieved',
        },
        message: 'All prices list Retrieved',
      });
    })
    .catch(() => {
      reject(new Error('Failed to retrieve price list'));
    });
});
