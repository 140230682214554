/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable import/prefer-default-export */
import * as types from './paymentIntent.constants';

const initialState = {
  loading: false,
  paymentIntentList: [] as any[],
};

export const paymentIntentListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ALL_PAYMENT_INTENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentIntentList: action.payload.data,
      };

    case types.GET_ALL_PAYMENT_INTENT_FAILED:
      return {
        ...state,
        loading: false,
        paymentIntentList: [],
      };

    default:
      return state;
  }
};
