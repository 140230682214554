import * as types from './Payment.constants';

export const getPriceListRequested = () => ({
  type: types.GET_ALL_PRICES_REQUESTED,
});

export const getPriceListSuccess = (payload: any) => ({
  type: types.GET_ALL_PRICES_SUCCESS,
  payload,
});

export const getPriceListFailed = (payload: any) => ({
  type: types.GET_ALL_PRICES_FAILED,
  payload,
});

export const createClientSecretRequested = () => ({
  type: types.CREATE_CLIENT_SECRET_REQUESTED,
});

export const createClientSecretSuccess = (payload: any) => ({
  type: types.CREATE_CLIENT_SECRET_SUCCESS,
  payload,
});

export const createClientSecretFailed = (payload: any) => ({
  type: types.CREATE_CLIENT_SECRET_FAILED,
  payload,
});

export const updateClientSecretRequested = () => ({
  type: types.UPDATE_CLIENT_SECRET_REQUESTED,
});

export const updateClientSecretSuccess = (payload: any) => ({
  type: types.UPDATE_CLIENT_SECRET_SUCCESS,
  payload,
});

export const updateClientSecretFailed = (payload: any) => ({
  type: types.UPDATE_CLIENT_SECRET_FAILED,
  payload,
});

export const cancelClientSecretRequested = () => ({
  type: types.CANCEL_CLIENT_SECRET_REQUESTED,
});
export const cancelClientSecretSuccess = (payload: any) => ({
  type: types.CANCEL_CLIENT_SECRET_SUCCESS,
  payload,
});
export const cancelClientSecretFailed = (payload: any) => ({
  type: types.CANCEL_CLIENT_SECRET_FAILED,
  payload,
});
