import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';

interface FooterProps {
  handlePrevious: () => void;
  handleNext: () => void;
  activeComponentIndex: number;
  loading: boolean;
  showPreviousButton: boolean;
  showNextButton: boolean;
}

function Footer(props: FooterProps) {
  const {
    handlePrevious,
    handleNext,
    loading,
    showPreviousButton,
    showNextButton,
    activeComponentIndex,
  } = props;

  const handleCancel = () => {
    window.location.href = import.meta.env.VITE_CANCEL_URL;
  };

  return (
    <div className="flex gap-3 pb-6">
      <button
        type="button"
        onClick={handlePrevious}
        className={`px-5 py-2 border-[1px] border-[#D1D5DB] footer-button-shadow rounded text-sm font-bold text-[#374151] hover:scale-105
        transition-all duration-200 hover:border-primary ${
          activeComponentIndex === 0 ? 'hidden' : 'flex'
        } ${
          showPreviousButton ? 'opacity-50 cursor-not-allowed' : ''
        } items-center gap-2`}
        disabled={showPreviousButton}
      >
        <AiOutlineArrowLeft size={18} />
        Previous
      </button>
      <button
        type="button"
        className="px-5 py-2 border-[1px] border-[#D1D5DB] footer-button-shadow rounded text-sm font-bold text-[#374151] hover:scale-105 hover:border-primary
        transition-all duration-200 flex items-center gap-1"
        disabled={loading}
        onClick={handleCancel}
      >
        Cancel
        {' '}
        <MdClear size={20} />
      </button>
      <button
        type="button"
        onClick={handleNext}
        className={`px-5 py-2 border-[1px] border-[#D1D5DB] footer-button-shadow rounded text-sm font-bold text-[#374151] hover:scale-105
        transition-all duration-200 hover:border-primary ${
          loading ? 'opacity-50 cursor-not-allowed' : ''
        } ${showNextButton ? 'flex' : 'hidden'} items-center gap-2`}
        disabled={loading}
      >
        {loading ? (
          <span>Loading...</span>
        ) : (
          <>
            Next
            <AiOutlineArrowRight size={18} />
          </>
        )}
      </button>
    </div>
  );
}

export default Footer;
