import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { MdOutlineClear } from 'react-icons/md';
import { getSelectedCustomerData } from '../../paymentModule/components/userInformation/customerRedux/Customer.thunks';
import { sendMail } from './sendMailRedux/sendMail.thunks';

interface Props {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const mapStateToProps = (state: AppState) => ({
  customerData: state.CustomerListReducer.customerData,
  isLoading: state.CustomerListReducer.loading,
  mailLoading: state.sendMailReducer.loading,
});

const mapDispatchToProps = {
  getSelectedCustomer: getSelectedCustomerData,
  sendEMail: sendMail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface Props extends ConnectedProps<typeof connector> {}

function ContactFormModal(props: Props) {
  const {
    openModal,
    setOpenModal,
    getSelectedCustomer,
    customerData,
    isLoading,
    mailLoading,
    sendEMail,
  } = props;

  const [commands, setCommands] = useState('');
  const [commandsError, setCommandsError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const customerId: any = sessionStorage.getItem('customerId');
    getSelectedCustomer(customerId);
  }, [getSelectedCustomer]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (commands.trim() === '') {
      setCommandsError(true);
    } else {
      setCommandsError(false);
      const payload = {
        data: {
          personalizations: [
            {
              to: [
                {
                  email: import.meta.env.VITE_TO_EMAIL_ID,
                  name: import.meta.env.VITE_TO_EMAIL_NAME,
                },
                {
                  email: customerData?.customer?.email,
                  name: customerData?.customer?.name,
                },
              ],
            },
          ],
          from: {
            email: import.meta.env.VITE_FROM_EMAIL_ID,
            name: import.meta.env.VITE_FROM_EMAIL_NAME,
          },
          subject: `Enquiry on the Enterprise Plan for the organization ${customerData?.customer?.description}`,
          content: [
            {
              type: 'text/html',
              value: `Hi Team,
                     <p>We have a new Enquiry for the Enterprise from ${customerData?.customer?.description}<br>   
                      ContactName: <strong>${customerData?.customer?.name}</strong> <br>
                      ContactEmail: <strong>${customerData?.customer?.email}</strong><br>
                      MoreInformation: ${commands}</p>`,
            },
          ],
        },
      };

      sendEMail(payload)
        .then((response) => {
          if (response.payload.status === 200) {
            navigate('/mail/success');
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  return (
    <Dialog
      visible={openModal}
      onHide={() => setOpenModal(false)}
      dismissableMask
      style={{ width: '40vw' }}
      breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      showHeader={false}
      pt={{
        mask: {
          className: 'mask-overlay',
        },
        root: {
          className: 'border-none shadow-lg',
        },
        content: {
          className: 'bg-light-color padding-none rounded-lg modal-content',
        },
      }}
    >
      {isLoading ? (
        <div className="flex justify-center items-center py-20">
          <RotatingLines
            strokeColor="var(--primary-color)"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible
          />
        </div>
      ) : (
        <div>
          <div className="flex justify-between items-center shadow px-6 py-4">
            <h1 className="text-xl font-extrabold text-gray-900">Contact Form</h1>

            <button
              type="button"
              className="hover:bg-gray-200 text-lg size-10 hover:shadow-lg rounded-full flex items-center justify-center"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <MdOutlineClear />
            </button>
          </div>
          <form className="w-full py-4 px-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 w-full">
              <div className="flex flex-col items-start">
                <h1 className="text-gray-700 mb-1 text-sm font-medium">
                  Company
                </h1>
                <input
                  id="company"
                  name="company"
                  type="text"
                  className="border-[1px] w-full px-3 py-2 text-sm rounded-md border-gray-300 outline-none focus:border-primary"
                  value={customerData?.customer?.description}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-gray-700 mb-1 text-sm font-medium">
                  Name
                </h1>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="border-[1px] w-full px-3 py-2 text-sm rounded-md border-gray-300 outline-none focus:border-primary"
                  value={customerData?.customer?.name}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-gray-700 mb-1 text-sm font-medium">
                  Email
                </h1>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="border-[1px] w-full px-3 py-2 text-sm rounded-md border-gray-300 outline-none focus:border-primary"
                  value={customerData?.customer?.email}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-gray-700 mb-1 text-sm font-medium">
                  Commands
                  {' '}
                  <span className="text-red-500">*</span>
                </h1>
                <textarea
                  id="commands"
                  name="email"
                  rows={8}
                  className={`border-[1px] w-full px-3 py-2 text-sm rounded-md outline-none ${
                    commandsError ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'
                  }`}
                  value={commands}
                  onChange={(e) => {
                    setCommands(e.target.value);
                    setCommandsError(false);
                  }}
                />
                {commandsError && (
                  <p
                    className={`error-message text-red-500 text-xs ${
                      commandsError ? 'opacity-100' : 'opacity-0'
                    } transition-opacity duration-300 ease-in-out`}
                  >
                    Commands is required.
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-end items-center gap-4 mt-4 mb-2">
              <button
                type="button"
                className="border-primary border-[2px] text-primary px-4 py-2 rounded-lg font-medium shadow-md hover:bg-border-600
                hover:shadow-2xl transition-all duration-500"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`bg-primary text-white border-[2px] border-primary px-4 py-2 rounded-lg font-medium shadow-md
                  hover:bg-orange-600 hover:shadow-2xl transition-all duration-500 flex items-center justify-center gap-4
                  ${mailLoading ? 'opacity-70' : 'opacity-1'}`}
              >
                {mailLoading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>
        </div>
      )}
    </Dialog>
  );
}

export default connector(ContactFormModal);
