/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable import/prefer-default-export */
import * as types from './Payment.constants';

const initialState = {
  loading: false,
  priceList: [] as any[],
  clientSecretData: [] as any[],
  cancelSubscriptionData: [] as any[],
};

export const priceListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ALL_PRICES_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_PRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        priceList: action.payload.data,
      };

    case types.GET_ALL_PRICES_FAILED:
      return {
        ...state,
        loading: false,
        priceList: [],
      };

    case types.CREATE_CLIENT_SECRET_REQUESTED:
      return {
        ...state,
        clientSecretData: [],
        loading: true,
      };

    case types.CREATE_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        loading: true,
        clientSecretData: action.payload.data,
      };

    case types.CREATE_CLIENT_SECRET_FAILED:
      return {
        ...state,
        loading: false,
        clientSecretData: [],
      };
    case types.CANCEL_CLIENT_SECRET_REQUESTED:
      return {
        ...state,
        loading: false,
      };
    case types.CANCEL_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        loading: true,
        cancelSubscriptionData: action.payload.data,
      };
    case types.CANCEL_CLIENT_SECRET_FAILED:
      return {
        ...state,
        loading: false,
        cancelSubscriptionData: [],
      };
    case types.UPDATE_CLIENT_SECRET_REQUESTED:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        loading: true,
        clientSecretData: action.payload.data,
      };

    case types.UPDATE_CLIENT_SECRET_FAILED:
      return {
        ...state,
        loading: false,
        clientSecretData: [],
      };

    default:
      return state;
  }
};
