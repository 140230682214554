/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable import/prefer-default-export */
import * as types from './Product.constants';

const initialState = {
  loading: false,
  productList: [] as any[],
};

export const productListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ALL_PRODUCTS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: action.payload.data,
      };

    case types.GET_ALL_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        productList: [],
      };

    default:
      return state;
  }
};
