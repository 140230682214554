import * as types from './Customer.constants';

export const getCustomersListRequested = () => ({
  type: types.GET_ALL_CUSTOMERS_REQUESTED,
});

export const getCustomersListSuccess = (payload: any) => ({
  type: types.GET_ALL_CUSTOMERS_SUCCESS,
  payload,
});

export const getCustomersListFailed = (payload: any) => ({
  type: types.GET_ALL_CUSTOMERS_FAILED,
  payload,
});

export const createCustomerRequested = () => ({
  type: types.CREATE_CUSTOMERS_REQUESTED,
});

export const createCustomerSuccess = (payload: any) => ({
  type: types.CREATE_CUSTOMERS_SUCCESS,
  payload,
});

export const createCustomerFailed = (payload: any) => ({
  type: types.CREATE_CUSTOMERS_FAILED,
  payload,
});

export const getCustomerDataRequested = () => ({
  type: types.GET_CUSTOMER_DATA_REQUESTED,
});

export const getCustomerDataSuccess = (payload: any) => ({
  type: types.GET_CUSTOMER_DATA_SUCCESS,
  payload,
});

export const getCustomerDataFailed = (payload: any) => ({
  type: types.GET_CUSTOMER_DATA_FAILED,
  payload,
});

export const getValidatorCustomerDataRequested = () => ({
  type: types.GET_VALIDATOR_CUSTOMER_DATA_REQUESTED,
});

export const getValidatorCustomerDataSuccess = (payload: any) => ({
  type: types.GET_VALIDATOR_CUSTOMER_DATA_SUCCESS,
  payload,
});

export const getValidatorCustomerDataFailed = (payload: any) => ({
  type: types.GET_VALIDATOR_CUSTOMER_DATA_FAILED,
  payload,
});
