import Logo from '../assets/logo.svg';

function Header() {
  return (
    <div className="bg-sky-950 w-full lg:px-16 px-8 py-4">
      <div>
        <img src={Logo} alt="logo" />
      </div>
    </div>
  );
}

export default Header;
