import NotFoundImage from '../assets/NotFoundImage.svg';

function NotFoundScreen() {
  const handleGoBack = () => {
    window.location.href = import.meta.env.VITE_CANCEL_URL;
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <img src={NotFoundImage} alt="" />
      <div className="py-6 flex flex-col justify-center gap-6 items-center">
        <h1 className="font-medium tracking-[1px] uppercase text-[#565872]">
          oops! page not found
        </h1>
        <div>
          <button
            type="button"
            className="bg-indigo-500 px-4 py-2 rounded-full w-36 text-white font-semibold shadow-xl hover:bg-indigo-600"
            onClick={handleGoBack}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotFoundScreen;
