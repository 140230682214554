import { useLocation } from 'react-router-dom';
import { ConnectedProps, connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import PaymentModule from '../modules/paymentModule/PaymentModule';
import {
  createNewCustomer,
  getCustomerList,
  validateCustomer,
} from '../modules/paymentModule/components/userInformation/customerRedux/Customer.thunks';
import unAuthorizedImage from '../assets/unauth.svg';

const mapStateToProps = (state: AppState) => ({
  customerList: state.CustomerListReducer.customerList,
  isLoading: state.CustomerListReducer.createUserLoading,
  validateCustomerData: state.CustomerListReducer.customerData,
});

const mapDispatchToProps = {
  getCustomerListProps: getCustomerList,
  createCustomer: createNewCustomer,
  validateUser: validateCustomer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface Props extends ConnectedProps<typeof connector> {}

function PaymentScreen(props: Props) {
  const { createCustomer, isLoading, validateUser } = props;

  const [showErrorComponent, setShowErrorComponent] = useState(false);

  const location = useLocation();

  const handleInvalidAccess = () => {
    toast.error('Invalid access!');
    setShowErrorComponent(true);
  };

  const handleInvalidDecodedData = () => {
    toast.error('Error occurred! Please Try after some Time...!');
    setShowErrorComponent(true);
  };

  const handleInvalidCredentials = () => {
    toast.error('Invalid credentials!');
    setShowErrorComponent(true);
  };

  const generateUserData = (userDecoded:any) => {
    const combinedName = `${userDecoded.data.given_name} ${userDecoded.data.family_name}`;

    return {
      name: combinedName,
      description: userDecoded.data['custom:organization_name'],
      email: userDecoded.data.email,
      metadata: {
        external_customer_id: userDecoded.data.sub,
        external_organization_name: userDecoded.data['custom:organization_name'],
        external_organization_id: userDecoded.data['custom:organization_id'],
      },
    };
  };
  useEffect(() => {
    const fetchData = async () => {
      sessionStorage.clear();
      const parameters = new URLSearchParams(location.search);
      const userEncryptedData = parameters.get('access_token');
      if (!userEncryptedData) {
        handleInvalidAccess();
        return;
      }
      const userEncoded = userEncryptedData.replace(/ /g, '+');
      try {
        const existingCustomerId = sessionStorage.getItem('customerId');
        if (existingCustomerId) {
          return;
        }
        const userDecoded = typeof userEncoded === 'string';
        if (userDecoded) {
          validateUser({ access_token: userEncoded }).then((response) => {
            const user = response.payload.data;
            const redirectUrl = user.data['custom:ps_redirect_url'] ?? '';
            if (response.payload.data.valid) {
              sessionStorage.setItem(
                'redirect_url',
                redirectUrl,
              );
              const modifiedUserData = generateUserData(user);
              createCustomer(modifiedUserData).then((responseCustomer) => {
                const customerId = responseCustomer.payload.data.customers.id;
                sessionStorage.setItem('customerId', customerId);
              }).catch((error) => toast.error(`Error occurred while setting up!${error}`));
            } else {
              handleInvalidDecodedData();
            }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          }).catch((_error) => { handleInvalidCredentials(); });
        } else {
          handleInvalidDecodedData();
        }
      } catch (error) {
        handleInvalidCredentials();
      }
    };

    fetchData();
  }, [createCustomer, location.search, validateUser]);

  return (
    <div>
      {showErrorComponent ? (
        <div className="flex flex-col h-screen items-center justify-center">
          <img src={unAuthorizedImage} alt="" />
          <div className="text-gray-600 font-medium">
            <h1 className="font-medium tracking-[1px] text-[#565872] text-center">
              Something went wrong!
            </h1>
            <p className="font-medium tracking-[1px] text-[#565872] text-center">
              You cannot access this page
            </p>
          </div>
        </div>
      ) : (
        <div>
          {isLoading ? (
            <div className="h-screen flex flex-col w-full items-center justify-center">
              <RotatingLines
                strokeColor="var(--primary-color)"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible
              />
              <h1 className="pt-2 text-gray-500">Please wait...</h1>
            </div>
          ) : (
            <PaymentModule />
          )}
        </div>
      )}
    </div>
  );
}

export default connector(PaymentScreen);
