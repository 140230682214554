/* eslint-disable import/prefer-default-export */
import { sendEmail } from '../../../../api/sendMail.api';
import * as actions from './sendMail.actions';

export const sendMail = (payload: any) => (dispatch: any) => {
  dispatch(actions.sendMailRequested());
  return sendEmail(payload)
    .then((res) => dispatch(actions.sendMailSuccess(res)))
    .catch((err) => Promise.reject(dispatch(actions.sendMailFailed(err))));
};
